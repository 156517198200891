import React from 'react';
import { Menu } from './menu';
import WhatsApp from './assets/whatsapp.png';

function App() {
  return (
    <div className='App '>
      <Menu />
      <a href="https://api.whatsapp.com/send?phone=919996212490" target='_blank' rel="noreferrer" className='whatsApp'>
        <img src={WhatsApp} alt='Shivay Durga' width={36} height={36} />
      </a>
    </div>
  );
}

export default App;
